<template>
    <div class="max-w-sm mx-auto mt-257 px-12 md:px-0">
        <Title
            :title="$t('signUpCodeFormTitle')"
            :subtitle="$t('signUpCodeFormSubtitle')"
        />

        <!-- General error message -->
        <p
            v-if="error"
            class="text-red-500 small my-10"
        >
            {{error}}
        </p>

        <form
            @submit.prevent="onSubmit"
            ref="signUpCodeForm"
        >
            <!-- SMS code input -->
            <Input
                v-on:input="onInput"
                :error="errors.sms_code"
                class="mb-22"
                :label="$t('signUpCodeFieldLabel')"
                :placeholder="$t('signUpCodeFieldPlaceholder')"
                type="password"
                name="signUpCode"
            />

            <p class="mb-37">
                <Counter @disabled="disabledExpiration = true"/>
            </p>

            <div class="w-full flex justify-center mb-36">
                <Button
                    class="button bg-purple-500 text-white hover:bg-purple-600"
                    :disabled="disabledExpiration ? disabledExpiration : disabled || registerValidateLoading"
                    role="submit"
                    :text="$t('signUpCodeButtonText')"
                    :spinning="registerValidateLoading"
                />
            </div>
        </form>
    </div>
</template>

<script>
import Input from '@/components/form/Input'
import Button from '@/components/buttons/Button'
import Title from '@/components/texts/FormTitle'
import { mapGetters, mapActions } from 'vuex'
import Counter from '@/components/counter/Counter'

export default {
    name: 'SignupValidate',

    components: {
        Input,
        Button,
        Title,
        Counter
    },

    data () {
        return {
            disabled: true,
            disabledExpiration: false,
            registerValidateLoading: false
        }
    },

    computed: {
        /** Get vuex state. */
        ...mapGetters(['activationCode', 'token', 'userType', 'errors', 'error'])
    },

    methods: {
        /** Vuex actions. */
        ...mapActions(['signupValidateCode']),

        /** Activate submit button. */
        onInput (event) {
            if (event.length === 6) {
                this.disabled = false
            }
        },

        /** Submit method for activation_code and sms_code. */
        onSubmit () {
            this.registerValidateLoading = true

            this.signupValidateCode({
                activation_code: this.activationCode,
                sms_code: this.$refs.signUpCodeForm.signUpCode.value
            })
                .then(() => {
                    this.registerValidateLoading = false
                })
        }
    }
}
</script>
